import validate from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45login_45domain_45global from "/opt/buildhome/repo/middleware/01.loginDomain.global.ts";
import _02_45auth_45global from "/opt/buildhome/repo/middleware/02.auth.global.ts";
import hide_45submenu_45global from "/opt/buildhome/repo/middleware/hideSubmenu.global.ts";
import manifest_45route_45rule from "/opt/buildhome/repo/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45login_45domain_45global,
  _02_45auth_45global,
  hide_45submenu_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}