import { default as invalid_45password_45reset_45tokenktfsjCuD1hMeta } from "/opt/buildhome/repo/pages/auth/invalid-password-reset-token.vue?macro=true";
import { default as _91accountSlug_93IQDENaGAtJMeta } from "/opt/buildhome/repo/pages/auth/login/[accountSlug].vue?macro=true";
import { default as indexzLfJUIfcqEMeta } from "/opt/buildhome/repo/pages/auth/login/index.vue?macro=true";
import { default as request_45password_45reset_45confirmation4Gcj0ysSjJMeta } from "/opt/buildhome/repo/pages/auth/request-password-reset-confirmation.vue?macro=true";
import { default as request_45password_45resetei2CFiaJGXMeta } from "/opt/buildhome/repo/pages/auth/request-password-reset.vue?macro=true";
import { default as reset_45password_45confirmation7ZP7mxXXdRMeta } from "/opt/buildhome/repo/pages/auth/reset-password-confirmation.vue?macro=true";
import { default as reset_45passwordh7H23OMrjXMeta } from "/opt/buildhome/repo/pages/auth/reset-password.vue?macro=true";
import { default as settingsTPNI77q8aXMeta } from "/opt/buildhome/repo/pages/auth/settings.vue?macro=true";
import { default as _91hash_93VyRD9fQviQMeta } from "/opt/buildhome/repo/pages/auth/verify-email/[userId]/[hash].vue?macro=true";
import { default as _91categoryId_93jYT1O5LNONMeta } from "/opt/buildhome/repo/pages/categories/[categoryId].vue?macro=true";
import { default as _91salesChannelId_93xJfQUhtm1VMeta } from "/opt/buildhome/repo/pages/categories/create/[salesChannelId].vue?macro=true";
import { default as index6yDS2Nat0aMeta } from "/opt/buildhome/repo/pages/categories/index.vue?macro=true";
import { default as categoriesi2G3xcJEDRMeta } from "/opt/buildhome/repo/pages/categories.vue?macro=true";
import { default as codesIh6NUVaI4CMeta } from "/opt/buildhome/repo/pages/discounts/[discountId]/codes.vue?macro=true";
import { default as indexIbxEE3ANTPMeta } from "/opt/buildhome/repo/pages/discounts/[discountId]/index.vue?macro=true";
import { default as createw45JYHHXSXMeta } from "/opt/buildhome/repo/pages/discounts/create.vue?macro=true";
import { default as index5GWJdjIdApMeta } from "/opt/buildhome/repo/pages/discounts/index.vue?macro=true";
import { default as _91enhancementId_93cYbUCuXFEjMeta } from "/opt/buildhome/repo/pages/enhancements/[enhancementId].vue?macro=true";
import { default as createaeA6tXEPKDMeta } from "/opt/buildhome/repo/pages/enhancements/create.vue?macro=true";
import { default as indexNhIH2KORLkMeta } from "/opt/buildhome/repo/pages/enhancements/index.vue?macro=true";
import { default as enhancementsyXoJzs6HxBMeta } from "/opt/buildhome/repo/pages/enhancements.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as _91invitationId_93cqHngTrnl4Meta } from "/opt/buildhome/repo/pages/invitation/[invitationId].vue?macro=true";
import { default as invalidhJxTarEej9Meta } from "/opt/buildhome/repo/pages/invitation/invalid.vue?macro=true";
import { default as _91orderId_935DI3UWSJJoMeta } from "/opt/buildhome/repo/pages/orders/[orderId].vue?macro=true";
import { default as indexfn0Xu6bTriMeta } from "/opt/buildhome/repo/pages/orders/index.vue?macro=true";
import { default as orderspVyfxG93eVMeta } from "/opt/buildhome/repo/pages/orders.vue?macro=true";
import { default as indexR53ivaqxxAMeta } from "/opt/buildhome/repo/pages/products/[productId]/index.vue?macro=true";
import { default as _91variantId_93AIoZKTEytuMeta } from "/opt/buildhome/repo/pages/products/[productId]/variants/[variantId].vue?macro=true";
import { default as create9PiAkKkhybMeta } from "/opt/buildhome/repo/pages/products/[productId]/variants/create.vue?macro=true";
import { default as _91type_93desB2ZN3aaMeta } from "/opt/buildhome/repo/pages/products/create/[type].vue?macro=true";
import { default as createjt4eqUyGuqMeta } from "/opt/buildhome/repo/pages/products/create/variants/create.vue?macro=true";
import { default as indexfRsRvEd4TiMeta } from "/opt/buildhome/repo/pages/products/index.vue?macro=true";
import { default as products2T6Jgugpz6Meta } from "/opt/buildhome/repo/pages/products.vue?macro=true";
import { default as indexGevHmlQqv9Meta } from "/opt/buildhome/repo/pages/reports/index.vue?macro=true";
import { default as reportsXblRqgf8cKMeta } from "/opt/buildhome/repo/pages/reports.vue?macro=true";
import { default as billinga8ZfFn34gNMeta } from "/opt/buildhome/repo/pages/settings/billing.vue?macro=true";
import { default as _91hostname_93GmbGgIj5x2Meta } from "/opt/buildhome/repo/pages/settings/email-domains/[hostname].vue?macro=true";
import { default as indexXuTd1zRNxGMeta } from "/opt/buildhome/repo/pages/settings/email-domains/index.vue?macro=true";
import { default as email_45domainsnKawtjh0CKMeta } from "/opt/buildhome/repo/pages/settings/email-domains.vue?macro=true";
import { default as generalOlxGhMSA1zMeta } from "/opt/buildhome/repo/pages/settings/general.vue?macro=true";
import { default as indexGjvb4dkXLuMeta } from "/opt/buildhome/repo/pages/settings/index.vue?macro=true";
import { default as paypalWXNvW21PQDMeta } from "/opt/buildhome/repo/pages/settings/payment-gateways/connect/paypal.vue?macro=true";
import { default as stripe0mfr5MC9VLMeta } from "/opt/buildhome/repo/pages/settings/payment-gateways/connect/stripe.vue?macro=true";
import { default as indexEg336BPiopMeta } from "/opt/buildhome/repo/pages/settings/payment-gateways/index.vue?macro=true";
import { default as _91redeemLocationId_93CUsh3mnPNqMeta } from "/opt/buildhome/repo/pages/settings/redeem-locations/[redeemLocationId].vue?macro=true";
import { default as createC5x3BIxsKGMeta } from "/opt/buildhome/repo/pages/settings/redeem-locations/create.vue?macro=true";
import { default as indexgy53kswKBzMeta } from "/opt/buildhome/repo/pages/settings/redeem-locations/index.vue?macro=true";
import { default as redeem_45locations3gtKCkroFgMeta } from "/opt/buildhome/repo/pages/settings/redeem-locations.vue?macro=true";
import { default as _91userId_93NJ7Scxsv9AMeta } from "/opt/buildhome/repo/pages/settings/users/[userId].vue?macro=true";
import { default as emailQtiL0mCV9BMeta } from "/opt/buildhome/repo/pages/settings/users/create/email.vue?macro=true";
import { default as usernameOsWKIvA88HMeta } from "/opt/buildhome/repo/pages/settings/users/create/username.vue?macro=true";
import { default as indexXu5KbXbpH1Meta } from "/opt/buildhome/repo/pages/settings/users/index.vue?macro=true";
import { default as _91invitationId_93JbuuAPgcJvMeta } from "/opt/buildhome/repo/pages/settings/users/invitations/[invitationId].vue?macro=true";
import { default as index0vkw1WneBhMeta } from "/opt/buildhome/repo/pages/settings/users/invitations/index.vue?macro=true";
import { default as userszZkKEWjH4lMeta } from "/opt/buildhome/repo/pages/settings/users.vue?macro=true";
import { default as settings4cDYwTsA31Meta } from "/opt/buildhome/repo/pages/settings.vue?macro=true";
import { default as _91page_93Z4A7VPj7CtMeta } from "/opt/buildhome/repo/pages/stores/[salesChannelId]/emails/[page].vue?macro=true";
import { default as indexyjBpFydHOCMeta } from "/opt/buildhome/repo/pages/stores/[salesChannelId]/emails/index.vue?macro=true";
import { default as faqVYFqPNpphUMeta } from "/opt/buildhome/repo/pages/stores/[salesChannelId]/faq.vue?macro=true";
import { default as indexjH785KxkYCMeta } from "/opt/buildhome/repo/pages/stores/[salesChannelId]/index.vue?macro=true";
import { default as privacybc3hOLgjsbMeta } from "/opt/buildhome/repo/pages/stores/[salesChannelId]/privacy.vue?macro=true";
import { default as termspQjEdFeQghMeta } from "/opt/buildhome/repo/pages/stores/[salesChannelId]/terms.vue?macro=true";
import { default as _91page_93uSPjstlimmMeta } from "/opt/buildhome/repo/pages/stores/create/[type]/emails/[page].vue?macro=true";
import { default as indexiFFIkqwAm1Meta } from "/opt/buildhome/repo/pages/stores/create/[type]/emails/index.vue?macro=true";
import { default as faqQUKq8fgm5qMeta } from "/opt/buildhome/repo/pages/stores/create/[type]/faq.vue?macro=true";
import { default as indexau5RcRKKLtMeta } from "/opt/buildhome/repo/pages/stores/create/[type]/index.vue?macro=true";
import { default as privacyedY47fiiILMeta } from "/opt/buildhome/repo/pages/stores/create/[type]/privacy.vue?macro=true";
import { default as terms44Dz2Mq9XHMeta } from "/opt/buildhome/repo/pages/stores/create/[type]/terms.vue?macro=true";
import { default as indexqgavoFdOYGMeta } from "/opt/buildhome/repo/pages/stores/index.vue?macro=true";
import { default as interstitial5WWieI7jjyMeta } from "/opt/buildhome/repo/pages/stores/interstitial.vue?macro=true";
import { default as storesls1CbGc5ygMeta } from "/opt/buildhome/repo/pages/stores.vue?macro=true";
import { default as settingsDRuGUEIRLTMeta } from "/opt/buildhome/repo/pages/terminal/auth/settings.vue?macro=true";
import { default as indexcH83g3VAfTMeta } from "/opt/buildhome/repo/pages/terminal/index.vue?macro=true";
import { default as _91reference_93huzq4y0x7HMeta } from "/opt/buildhome/repo/pages/terminal/redeem/[reference].vue?macro=true";
import { default as confirmationiqEfo3qVMwMeta } from "/opt/buildhome/repo/pages/terminal/redeem/confirmation.vue?macro=true";
import { default as indexmt1Ap7eunWMeta } from "/opt/buildhome/repo/pages/terminal/redeem/index.vue?macro=true";
import { default as _91redeemableId_931RYVedKbQ5Meta } from "/opt/buildhome/repo/pages/terminal/redeem/voucher/[reference]/[redeemableId].vue?macro=true";
import { default as indexkVc81AzHfGMeta } from "/opt/buildhome/repo/pages/terminal/redeem/voucher/[reference]/index.vue?macro=true";
export default [
  {
    name: invalid_45password_45reset_45tokenktfsjCuD1hMeta?.name ?? "auth-invalid-password-reset-token",
    path: invalid_45password_45reset_45tokenktfsjCuD1hMeta?.path ?? "/auth/invalid-password-reset-token",
    meta: invalid_45password_45reset_45tokenktfsjCuD1hMeta || {},
    alias: invalid_45password_45reset_45tokenktfsjCuD1hMeta?.alias || [],
    redirect: invalid_45password_45reset_45tokenktfsjCuD1hMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/auth/invalid-password-reset-token.vue").then(m => m.default || m)
  },
  {
    name: _91accountSlug_93IQDENaGAtJMeta?.name ?? "auth-login-accountSlug",
    path: _91accountSlug_93IQDENaGAtJMeta?.path ?? "/auth/login/:accountSlug()",
    meta: _91accountSlug_93IQDENaGAtJMeta || {},
    alias: _91accountSlug_93IQDENaGAtJMeta?.alias || [],
    redirect: _91accountSlug_93IQDENaGAtJMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/auth/login/[accountSlug].vue").then(m => m.default || m)
  },
  {
    name: indexzLfJUIfcqEMeta?.name ?? "auth-login",
    path: indexzLfJUIfcqEMeta?.path ?? "/auth/login",
    meta: indexzLfJUIfcqEMeta || {},
    alias: indexzLfJUIfcqEMeta?.alias || [],
    redirect: indexzLfJUIfcqEMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/auth/login/index.vue").then(m => m.default || m)
  },
  {
    name: request_45password_45reset_45confirmation4Gcj0ysSjJMeta?.name ?? "auth-request-password-reset-confirmation",
    path: request_45password_45reset_45confirmation4Gcj0ysSjJMeta?.path ?? "/auth/request-password-reset-confirmation",
    meta: request_45password_45reset_45confirmation4Gcj0ysSjJMeta || {},
    alias: request_45password_45reset_45confirmation4Gcj0ysSjJMeta?.alias || [],
    redirect: request_45password_45reset_45confirmation4Gcj0ysSjJMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/auth/request-password-reset-confirmation.vue").then(m => m.default || m)
  },
  {
    name: request_45password_45resetei2CFiaJGXMeta?.name ?? "auth-request-password-reset",
    path: request_45password_45resetei2CFiaJGXMeta?.path ?? "/auth/request-password-reset",
    meta: request_45password_45resetei2CFiaJGXMeta || {},
    alias: request_45password_45resetei2CFiaJGXMeta?.alias || [],
    redirect: request_45password_45resetei2CFiaJGXMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/auth/request-password-reset.vue").then(m => m.default || m)
  },
  {
    name: reset_45password_45confirmation7ZP7mxXXdRMeta?.name ?? "auth-reset-password-confirmation",
    path: reset_45password_45confirmation7ZP7mxXXdRMeta?.path ?? "/auth/reset-password-confirmation",
    meta: reset_45password_45confirmation7ZP7mxXXdRMeta || {},
    alias: reset_45password_45confirmation7ZP7mxXXdRMeta?.alias || [],
    redirect: reset_45password_45confirmation7ZP7mxXXdRMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/auth/reset-password-confirmation.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordh7H23OMrjXMeta?.name ?? "auth-reset-password",
    path: reset_45passwordh7H23OMrjXMeta?.path ?? "/auth/reset-password",
    meta: reset_45passwordh7H23OMrjXMeta || {},
    alias: reset_45passwordh7H23OMrjXMeta?.alias || [],
    redirect: reset_45passwordh7H23OMrjXMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: settingsTPNI77q8aXMeta?.name ?? "auth-settings",
    path: settingsTPNI77q8aXMeta?.path ?? "/auth/settings",
    meta: settingsTPNI77q8aXMeta || {},
    alias: settingsTPNI77q8aXMeta?.alias || [],
    redirect: settingsTPNI77q8aXMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/auth/settings.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93VyRD9fQviQMeta?.name ?? "auth-verify-email-userId-hash",
    path: _91hash_93VyRD9fQviQMeta?.path ?? "/auth/verify-email/:userId()/:hash()",
    meta: _91hash_93VyRD9fQviQMeta || {},
    alias: _91hash_93VyRD9fQviQMeta?.alias || [],
    redirect: _91hash_93VyRD9fQviQMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/auth/verify-email/[userId]/[hash].vue").then(m => m.default || m)
  },
  {
    path: categoriesi2G3xcJEDRMeta?.path ?? "/categories",
    children: [
  {
    name: _91categoryId_93jYT1O5LNONMeta?.name ?? "categories-categoryId",
    path: _91categoryId_93jYT1O5LNONMeta?.path ?? ":categoryId()",
    meta: _91categoryId_93jYT1O5LNONMeta || {},
    alias: _91categoryId_93jYT1O5LNONMeta?.alias || [],
    redirect: _91categoryId_93jYT1O5LNONMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/categories/[categoryId].vue").then(m => m.default || m)
  },
  {
    name: _91salesChannelId_93xJfQUhtm1VMeta?.name ?? "categories-create-salesChannelId",
    path: _91salesChannelId_93xJfQUhtm1VMeta?.path ?? "create/:salesChannelId()",
    meta: _91salesChannelId_93xJfQUhtm1VMeta || {},
    alias: _91salesChannelId_93xJfQUhtm1VMeta?.alias || [],
    redirect: _91salesChannelId_93xJfQUhtm1VMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/categories/create/[salesChannelId].vue").then(m => m.default || m)
  },
  {
    name: index6yDS2Nat0aMeta?.name ?? "categories",
    path: index6yDS2Nat0aMeta?.path ?? "",
    meta: index6yDS2Nat0aMeta || {},
    alias: index6yDS2Nat0aMeta?.alias || [],
    redirect: index6yDS2Nat0aMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/categories/index.vue").then(m => m.default || m)
  }
],
    name: categoriesi2G3xcJEDRMeta?.name ?? undefined,
    meta: categoriesi2G3xcJEDRMeta || {},
    alias: categoriesi2G3xcJEDRMeta?.alias || [],
    redirect: categoriesi2G3xcJEDRMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/categories.vue").then(m => m.default || m)
  },
  {
    name: codesIh6NUVaI4CMeta?.name ?? "discounts-discountId-codes",
    path: codesIh6NUVaI4CMeta?.path ?? "/discounts/:discountId()/codes",
    meta: codesIh6NUVaI4CMeta || {},
    alias: codesIh6NUVaI4CMeta?.alias || [],
    redirect: codesIh6NUVaI4CMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/discounts/[discountId]/codes.vue").then(m => m.default || m)
  },
  {
    name: indexIbxEE3ANTPMeta?.name ?? "discounts-discountId",
    path: indexIbxEE3ANTPMeta?.path ?? "/discounts/:discountId()",
    meta: indexIbxEE3ANTPMeta || {},
    alias: indexIbxEE3ANTPMeta?.alias || [],
    redirect: indexIbxEE3ANTPMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/discounts/[discountId]/index.vue").then(m => m.default || m)
  },
  {
    name: createw45JYHHXSXMeta?.name ?? "discounts-create",
    path: createw45JYHHXSXMeta?.path ?? "/discounts/create",
    meta: createw45JYHHXSXMeta || {},
    alias: createw45JYHHXSXMeta?.alias || [],
    redirect: createw45JYHHXSXMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/discounts/create.vue").then(m => m.default || m)
  },
  {
    name: index5GWJdjIdApMeta?.name ?? "discounts",
    path: index5GWJdjIdApMeta?.path ?? "/discounts",
    meta: index5GWJdjIdApMeta || {},
    alias: index5GWJdjIdApMeta?.alias || [],
    redirect: index5GWJdjIdApMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/discounts/index.vue").then(m => m.default || m)
  },
  {
    path: enhancementsyXoJzs6HxBMeta?.path ?? "/enhancements",
    children: [
  {
    name: _91enhancementId_93cYbUCuXFEjMeta?.name ?? "enhancements-enhancementId",
    path: _91enhancementId_93cYbUCuXFEjMeta?.path ?? ":enhancementId()",
    meta: _91enhancementId_93cYbUCuXFEjMeta || {},
    alias: _91enhancementId_93cYbUCuXFEjMeta?.alias || [],
    redirect: _91enhancementId_93cYbUCuXFEjMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/enhancements/[enhancementId].vue").then(m => m.default || m)
  },
  {
    name: createaeA6tXEPKDMeta?.name ?? "enhancements-create",
    path: createaeA6tXEPKDMeta?.path ?? "create",
    meta: createaeA6tXEPKDMeta || {},
    alias: createaeA6tXEPKDMeta?.alias || [],
    redirect: createaeA6tXEPKDMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/enhancements/create.vue").then(m => m.default || m)
  },
  {
    name: indexNhIH2KORLkMeta?.name ?? "enhancements",
    path: indexNhIH2KORLkMeta?.path ?? "",
    meta: indexNhIH2KORLkMeta || {},
    alias: indexNhIH2KORLkMeta?.alias || [],
    redirect: indexNhIH2KORLkMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/enhancements/index.vue").then(m => m.default || m)
  }
],
    name: enhancementsyXoJzs6HxBMeta?.name ?? undefined,
    meta: enhancementsyXoJzs6HxBMeta || {},
    alias: enhancementsyXoJzs6HxBMeta?.alias || [],
    redirect: enhancementsyXoJzs6HxBMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/enhancements.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitationId_93cqHngTrnl4Meta?.name ?? "invitation-invitationId",
    path: _91invitationId_93cqHngTrnl4Meta?.path ?? "/invitation/:invitationId()",
    meta: _91invitationId_93cqHngTrnl4Meta || {},
    alias: _91invitationId_93cqHngTrnl4Meta?.alias || [],
    redirect: _91invitationId_93cqHngTrnl4Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/invitation/[invitationId].vue").then(m => m.default || m)
  },
  {
    name: invalidhJxTarEej9Meta?.name ?? "invitation-invalid",
    path: invalidhJxTarEej9Meta?.path ?? "/invitation/invalid",
    meta: invalidhJxTarEej9Meta || {},
    alias: invalidhJxTarEej9Meta?.alias || [],
    redirect: invalidhJxTarEej9Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/invitation/invalid.vue").then(m => m.default || m)
  },
  {
    path: orderspVyfxG93eVMeta?.path ?? "/orders",
    children: [
  {
    name: _91orderId_935DI3UWSJJoMeta?.name ?? "orders-orderId",
    path: _91orderId_935DI3UWSJJoMeta?.path ?? ":orderId()",
    meta: _91orderId_935DI3UWSJJoMeta || {},
    alias: _91orderId_935DI3UWSJJoMeta?.alias || [],
    redirect: _91orderId_935DI3UWSJJoMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/orders/[orderId].vue").then(m => m.default || m)
  },
  {
    name: indexfn0Xu6bTriMeta?.name ?? "orders",
    path: indexfn0Xu6bTriMeta?.path ?? "",
    meta: indexfn0Xu6bTriMeta || {},
    alias: indexfn0Xu6bTriMeta?.alias || [],
    redirect: indexfn0Xu6bTriMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/orders/index.vue").then(m => m.default || m)
  }
],
    name: orderspVyfxG93eVMeta?.name ?? undefined,
    meta: orderspVyfxG93eVMeta || {},
    alias: orderspVyfxG93eVMeta?.alias || [],
    redirect: orderspVyfxG93eVMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/orders.vue").then(m => m.default || m)
  },
  {
    path: products2T6Jgugpz6Meta?.path ?? "/products",
    children: [
  {
    name: indexR53ivaqxxAMeta?.name ?? "products-productId",
    path: indexR53ivaqxxAMeta?.path ?? ":productId()",
    meta: indexR53ivaqxxAMeta || {},
    alias: indexR53ivaqxxAMeta?.alias || [],
    redirect: indexR53ivaqxxAMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/products/[productId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91variantId_93AIoZKTEytuMeta?.name ?? "products-productId-variants-variantId",
    path: _91variantId_93AIoZKTEytuMeta?.path ?? ":productId()/variants/:variantId()",
    meta: _91variantId_93AIoZKTEytuMeta || {},
    alias: _91variantId_93AIoZKTEytuMeta?.alias || [],
    redirect: _91variantId_93AIoZKTEytuMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/products/[productId]/variants/[variantId].vue").then(m => m.default || m)
  },
  {
    name: create9PiAkKkhybMeta?.name ?? "products-productId-variants-create",
    path: create9PiAkKkhybMeta?.path ?? ":productId()/variants/create",
    meta: create9PiAkKkhybMeta || {},
    alias: create9PiAkKkhybMeta?.alias || [],
    redirect: create9PiAkKkhybMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/products/[productId]/variants/create.vue").then(m => m.default || m)
  },
  {
    name: _91type_93desB2ZN3aaMeta?.name ?? "products-create-type",
    path: _91type_93desB2ZN3aaMeta?.path ?? "create/:type()",
    meta: _91type_93desB2ZN3aaMeta || {},
    alias: _91type_93desB2ZN3aaMeta?.alias || [],
    redirect: _91type_93desB2ZN3aaMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/products/create/[type].vue").then(m => m.default || m)
  },
  {
    name: createjt4eqUyGuqMeta?.name ?? "products-create-variants-create",
    path: createjt4eqUyGuqMeta?.path ?? "create/variants/create",
    meta: createjt4eqUyGuqMeta || {},
    alias: createjt4eqUyGuqMeta?.alias || [],
    redirect: createjt4eqUyGuqMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/products/create/variants/create.vue").then(m => m.default || m)
  },
  {
    name: indexfRsRvEd4TiMeta?.name ?? "products",
    path: indexfRsRvEd4TiMeta?.path ?? "",
    meta: indexfRsRvEd4TiMeta || {},
    alias: indexfRsRvEd4TiMeta?.alias || [],
    redirect: indexfRsRvEd4TiMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/products/index.vue").then(m => m.default || m)
  }
],
    name: products2T6Jgugpz6Meta?.name ?? undefined,
    meta: products2T6Jgugpz6Meta || {},
    alias: products2T6Jgugpz6Meta?.alias || [],
    redirect: products2T6Jgugpz6Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/products.vue").then(m => m.default || m)
  },
  {
    path: reportsXblRqgf8cKMeta?.path ?? "/reports",
    children: [
  {
    name: indexGevHmlQqv9Meta?.name ?? "reports",
    path: indexGevHmlQqv9Meta?.path ?? "",
    meta: indexGevHmlQqv9Meta || {},
    alias: indexGevHmlQqv9Meta?.alias || [],
    redirect: indexGevHmlQqv9Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/reports/index.vue").then(m => m.default || m)
  }
],
    name: reportsXblRqgf8cKMeta?.name ?? undefined,
    meta: reportsXblRqgf8cKMeta || {},
    alias: reportsXblRqgf8cKMeta?.alias || [],
    redirect: reportsXblRqgf8cKMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/reports.vue").then(m => m.default || m)
  },
  {
    path: settings4cDYwTsA31Meta?.path ?? "/settings",
    children: [
  {
    name: billinga8ZfFn34gNMeta?.name ?? "settings-billing",
    path: billinga8ZfFn34gNMeta?.path ?? "billing",
    meta: billinga8ZfFn34gNMeta || {},
    alias: billinga8ZfFn34gNMeta?.alias || [],
    redirect: billinga8ZfFn34gNMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    path: email_45domainsnKawtjh0CKMeta?.path ?? "email-domains",
    children: [
  {
    name: _91hostname_93GmbGgIj5x2Meta?.name ?? "settings-email-domains-hostname",
    path: _91hostname_93GmbGgIj5x2Meta?.path ?? ":hostname()",
    meta: _91hostname_93GmbGgIj5x2Meta || {},
    alias: _91hostname_93GmbGgIj5x2Meta?.alias || [],
    redirect: _91hostname_93GmbGgIj5x2Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings/email-domains/[hostname].vue").then(m => m.default || m)
  },
  {
    name: indexXuTd1zRNxGMeta?.name ?? "settings-email-domains",
    path: indexXuTd1zRNxGMeta?.path ?? "",
    meta: indexXuTd1zRNxGMeta || {},
    alias: indexXuTd1zRNxGMeta?.alias || [],
    redirect: indexXuTd1zRNxGMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings/email-domains/index.vue").then(m => m.default || m)
  }
],
    name: email_45domainsnKawtjh0CKMeta?.name ?? undefined,
    meta: email_45domainsnKawtjh0CKMeta || {},
    alias: email_45domainsnKawtjh0CKMeta?.alias || [],
    redirect: email_45domainsnKawtjh0CKMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings/email-domains.vue").then(m => m.default || m)
  },
  {
    name: generalOlxGhMSA1zMeta?.name ?? "settings-general",
    path: generalOlxGhMSA1zMeta?.path ?? "general",
    meta: generalOlxGhMSA1zMeta || {},
    alias: generalOlxGhMSA1zMeta?.alias || [],
    redirect: generalOlxGhMSA1zMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings/general.vue").then(m => m.default || m)
  },
  {
    name: indexGjvb4dkXLuMeta?.name ?? "settings",
    path: indexGjvb4dkXLuMeta?.path ?? "",
    meta: indexGjvb4dkXLuMeta || {},
    alias: indexGjvb4dkXLuMeta?.alias || [],
    redirect: indexGjvb4dkXLuMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: paypalWXNvW21PQDMeta?.name ?? "settings-payment-gateways-connect-paypal",
    path: paypalWXNvW21PQDMeta?.path ?? "payment-gateways/connect/paypal",
    meta: paypalWXNvW21PQDMeta || {},
    alias: paypalWXNvW21PQDMeta?.alias || [],
    redirect: paypalWXNvW21PQDMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings/payment-gateways/connect/paypal.vue").then(m => m.default || m)
  },
  {
    name: stripe0mfr5MC9VLMeta?.name ?? "settings-payment-gateways-connect-stripe",
    path: stripe0mfr5MC9VLMeta?.path ?? "payment-gateways/connect/stripe",
    meta: stripe0mfr5MC9VLMeta || {},
    alias: stripe0mfr5MC9VLMeta?.alias || [],
    redirect: stripe0mfr5MC9VLMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings/payment-gateways/connect/stripe.vue").then(m => m.default || m)
  },
  {
    name: indexEg336BPiopMeta?.name ?? "settings-payment-gateways",
    path: indexEg336BPiopMeta?.path ?? "payment-gateways",
    meta: indexEg336BPiopMeta || {},
    alias: indexEg336BPiopMeta?.alias || [],
    redirect: indexEg336BPiopMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings/payment-gateways/index.vue").then(m => m.default || m)
  },
  {
    path: redeem_45locations3gtKCkroFgMeta?.path ?? "redeem-locations",
    children: [
  {
    name: _91redeemLocationId_93CUsh3mnPNqMeta?.name ?? "settings-redeem-locations-redeemLocationId",
    path: _91redeemLocationId_93CUsh3mnPNqMeta?.path ?? ":redeemLocationId()",
    meta: _91redeemLocationId_93CUsh3mnPNqMeta || {},
    alias: _91redeemLocationId_93CUsh3mnPNqMeta?.alias || [],
    redirect: _91redeemLocationId_93CUsh3mnPNqMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings/redeem-locations/[redeemLocationId].vue").then(m => m.default || m)
  },
  {
    name: createC5x3BIxsKGMeta?.name ?? "settings-redeem-locations-create",
    path: createC5x3BIxsKGMeta?.path ?? "create",
    meta: createC5x3BIxsKGMeta || {},
    alias: createC5x3BIxsKGMeta?.alias || [],
    redirect: createC5x3BIxsKGMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings/redeem-locations/create.vue").then(m => m.default || m)
  },
  {
    name: indexgy53kswKBzMeta?.name ?? "settings-redeem-locations",
    path: indexgy53kswKBzMeta?.path ?? "",
    meta: indexgy53kswKBzMeta || {},
    alias: indexgy53kswKBzMeta?.alias || [],
    redirect: indexgy53kswKBzMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings/redeem-locations/index.vue").then(m => m.default || m)
  }
],
    name: redeem_45locations3gtKCkroFgMeta?.name ?? undefined,
    meta: redeem_45locations3gtKCkroFgMeta || {},
    alias: redeem_45locations3gtKCkroFgMeta?.alias || [],
    redirect: redeem_45locations3gtKCkroFgMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings/redeem-locations.vue").then(m => m.default || m)
  },
  {
    path: userszZkKEWjH4lMeta?.path ?? "users",
    children: [
  {
    name: _91userId_93NJ7Scxsv9AMeta?.name ?? "settings-users-userId",
    path: _91userId_93NJ7Scxsv9AMeta?.path ?? ":userId()",
    meta: _91userId_93NJ7Scxsv9AMeta || {},
    alias: _91userId_93NJ7Scxsv9AMeta?.alias || [],
    redirect: _91userId_93NJ7Scxsv9AMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings/users/[userId].vue").then(m => m.default || m)
  },
  {
    name: emailQtiL0mCV9BMeta?.name ?? "settings-users-create-email",
    path: emailQtiL0mCV9BMeta?.path ?? "create/email",
    meta: emailQtiL0mCV9BMeta || {},
    alias: emailQtiL0mCV9BMeta?.alias || [],
    redirect: emailQtiL0mCV9BMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings/users/create/email.vue").then(m => m.default || m)
  },
  {
    name: usernameOsWKIvA88HMeta?.name ?? "settings-users-create-username",
    path: usernameOsWKIvA88HMeta?.path ?? "create/username",
    meta: usernameOsWKIvA88HMeta || {},
    alias: usernameOsWKIvA88HMeta?.alias || [],
    redirect: usernameOsWKIvA88HMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings/users/create/username.vue").then(m => m.default || m)
  },
  {
    name: indexXu5KbXbpH1Meta?.name ?? "settings-users",
    path: indexXu5KbXbpH1Meta?.path ?? "",
    meta: indexXu5KbXbpH1Meta || {},
    alias: indexXu5KbXbpH1Meta?.alias || [],
    redirect: indexXu5KbXbpH1Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings/users/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitationId_93JbuuAPgcJvMeta?.name ?? "settings-users-invitations-invitationId",
    path: _91invitationId_93JbuuAPgcJvMeta?.path ?? "invitations/:invitationId()",
    meta: _91invitationId_93JbuuAPgcJvMeta || {},
    alias: _91invitationId_93JbuuAPgcJvMeta?.alias || [],
    redirect: _91invitationId_93JbuuAPgcJvMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings/users/invitations/[invitationId].vue").then(m => m.default || m)
  },
  {
    name: index0vkw1WneBhMeta?.name ?? "settings-users-invitations",
    path: index0vkw1WneBhMeta?.path ?? "invitations",
    meta: index0vkw1WneBhMeta || {},
    alias: index0vkw1WneBhMeta?.alias || [],
    redirect: index0vkw1WneBhMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings/users/invitations/index.vue").then(m => m.default || m)
  }
],
    name: userszZkKEWjH4lMeta?.name ?? undefined,
    meta: userszZkKEWjH4lMeta || {},
    alias: userszZkKEWjH4lMeta?.alias || [],
    redirect: userszZkKEWjH4lMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings/users.vue").then(m => m.default || m)
  }
],
    name: settings4cDYwTsA31Meta?.name ?? undefined,
    meta: settings4cDYwTsA31Meta || {},
    alias: settings4cDYwTsA31Meta?.alias || [],
    redirect: settings4cDYwTsA31Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/settings.vue").then(m => m.default || m)
  },
  {
    path: storesls1CbGc5ygMeta?.path ?? "/stores",
    children: [
  {
    name: _91page_93Z4A7VPj7CtMeta?.name ?? "stores-salesChannelId-emails-page",
    path: _91page_93Z4A7VPj7CtMeta?.path ?? ":salesChannelId()/emails/:page()",
    meta: _91page_93Z4A7VPj7CtMeta || {},
    alias: _91page_93Z4A7VPj7CtMeta?.alias || [],
    redirect: _91page_93Z4A7VPj7CtMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/stores/[salesChannelId]/emails/[page].vue").then(m => m.default || m)
  },
  {
    name: indexyjBpFydHOCMeta?.name ?? "stores-salesChannelId-emails",
    path: indexyjBpFydHOCMeta?.path ?? ":salesChannelId()/emails",
    meta: indexyjBpFydHOCMeta || {},
    alias: indexyjBpFydHOCMeta?.alias || [],
    redirect: indexyjBpFydHOCMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/stores/[salesChannelId]/emails/index.vue").then(m => m.default || m)
  },
  {
    name: faqVYFqPNpphUMeta?.name ?? "stores-salesChannelId-faq",
    path: faqVYFqPNpphUMeta?.path ?? ":salesChannelId()/faq",
    meta: faqVYFqPNpphUMeta || {},
    alias: faqVYFqPNpphUMeta?.alias || [],
    redirect: faqVYFqPNpphUMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/stores/[salesChannelId]/faq.vue").then(m => m.default || m)
  },
  {
    name: indexjH785KxkYCMeta?.name ?? "stores-salesChannelId",
    path: indexjH785KxkYCMeta?.path ?? ":salesChannelId()",
    meta: indexjH785KxkYCMeta || {},
    alias: indexjH785KxkYCMeta?.alias || [],
    redirect: indexjH785KxkYCMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/stores/[salesChannelId]/index.vue").then(m => m.default || m)
  },
  {
    name: privacybc3hOLgjsbMeta?.name ?? "stores-salesChannelId-privacy",
    path: privacybc3hOLgjsbMeta?.path ?? ":salesChannelId()/privacy",
    meta: privacybc3hOLgjsbMeta || {},
    alias: privacybc3hOLgjsbMeta?.alias || [],
    redirect: privacybc3hOLgjsbMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/stores/[salesChannelId]/privacy.vue").then(m => m.default || m)
  },
  {
    name: termspQjEdFeQghMeta?.name ?? "stores-salesChannelId-terms",
    path: termspQjEdFeQghMeta?.path ?? ":salesChannelId()/terms",
    meta: termspQjEdFeQghMeta || {},
    alias: termspQjEdFeQghMeta?.alias || [],
    redirect: termspQjEdFeQghMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/stores/[salesChannelId]/terms.vue").then(m => m.default || m)
  },
  {
    name: _91page_93uSPjstlimmMeta?.name ?? "stores-create-type-emails-page",
    path: _91page_93uSPjstlimmMeta?.path ?? "create/:type()/emails/:page()",
    meta: _91page_93uSPjstlimmMeta || {},
    alias: _91page_93uSPjstlimmMeta?.alias || [],
    redirect: _91page_93uSPjstlimmMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/stores/create/[type]/emails/[page].vue").then(m => m.default || m)
  },
  {
    name: indexiFFIkqwAm1Meta?.name ?? "stores-create-type-emails",
    path: indexiFFIkqwAm1Meta?.path ?? "create/:type()/emails",
    meta: indexiFFIkqwAm1Meta || {},
    alias: indexiFFIkqwAm1Meta?.alias || [],
    redirect: indexiFFIkqwAm1Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/stores/create/[type]/emails/index.vue").then(m => m.default || m)
  },
  {
    name: faqQUKq8fgm5qMeta?.name ?? "stores-create-type-faq",
    path: faqQUKq8fgm5qMeta?.path ?? "create/:type()/faq",
    meta: faqQUKq8fgm5qMeta || {},
    alias: faqQUKq8fgm5qMeta?.alias || [],
    redirect: faqQUKq8fgm5qMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/stores/create/[type]/faq.vue").then(m => m.default || m)
  },
  {
    name: indexau5RcRKKLtMeta?.name ?? "stores-create-type",
    path: indexau5RcRKKLtMeta?.path ?? "create/:type()",
    meta: indexau5RcRKKLtMeta || {},
    alias: indexau5RcRKKLtMeta?.alias || [],
    redirect: indexau5RcRKKLtMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/stores/create/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: privacyedY47fiiILMeta?.name ?? "stores-create-type-privacy",
    path: privacyedY47fiiILMeta?.path ?? "create/:type()/privacy",
    meta: privacyedY47fiiILMeta || {},
    alias: privacyedY47fiiILMeta?.alias || [],
    redirect: privacyedY47fiiILMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/stores/create/[type]/privacy.vue").then(m => m.default || m)
  },
  {
    name: terms44Dz2Mq9XHMeta?.name ?? "stores-create-type-terms",
    path: terms44Dz2Mq9XHMeta?.path ?? "create/:type()/terms",
    meta: terms44Dz2Mq9XHMeta || {},
    alias: terms44Dz2Mq9XHMeta?.alias || [],
    redirect: terms44Dz2Mq9XHMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/stores/create/[type]/terms.vue").then(m => m.default || m)
  },
  {
    name: indexqgavoFdOYGMeta?.name ?? "stores",
    path: indexqgavoFdOYGMeta?.path ?? "",
    meta: indexqgavoFdOYGMeta || {},
    alias: indexqgavoFdOYGMeta?.alias || [],
    redirect: indexqgavoFdOYGMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/stores/index.vue").then(m => m.default || m)
  },
  {
    name: interstitial5WWieI7jjyMeta?.name ?? "stores-interstitial",
    path: interstitial5WWieI7jjyMeta?.path ?? "interstitial",
    meta: interstitial5WWieI7jjyMeta || {},
    alias: interstitial5WWieI7jjyMeta?.alias || [],
    redirect: interstitial5WWieI7jjyMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/stores/interstitial.vue").then(m => m.default || m)
  }
],
    name: storesls1CbGc5ygMeta?.name ?? undefined,
    meta: storesls1CbGc5ygMeta || {},
    alias: storesls1CbGc5ygMeta?.alias || [],
    redirect: storesls1CbGc5ygMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/stores.vue").then(m => m.default || m)
  },
  {
    name: settingsDRuGUEIRLTMeta?.name ?? "terminal-auth-settings",
    path: settingsDRuGUEIRLTMeta?.path ?? "/terminal/auth/settings",
    meta: settingsDRuGUEIRLTMeta || {},
    alias: settingsDRuGUEIRLTMeta?.alias || [],
    redirect: settingsDRuGUEIRLTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/terminal/auth/settings.vue").then(m => m.default || m)
  },
  {
    name: indexcH83g3VAfTMeta?.name ?? "terminal",
    path: indexcH83g3VAfTMeta?.path ?? "/terminal",
    meta: indexcH83g3VAfTMeta || {},
    alias: indexcH83g3VAfTMeta?.alias || [],
    redirect: indexcH83g3VAfTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/terminal/index.vue").then(m => m.default || m)
  },
  {
    name: _91reference_93huzq4y0x7HMeta?.name ?? "terminal-redeem-reference",
    path: _91reference_93huzq4y0x7HMeta?.path ?? "/terminal/redeem/:reference()",
    meta: _91reference_93huzq4y0x7HMeta || {},
    alias: _91reference_93huzq4y0x7HMeta?.alias || [],
    redirect: _91reference_93huzq4y0x7HMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/terminal/redeem/[reference].vue").then(m => m.default || m)
  },
  {
    name: confirmationiqEfo3qVMwMeta?.name ?? "terminal-redeem-confirmation",
    path: confirmationiqEfo3qVMwMeta?.path ?? "/terminal/redeem/confirmation",
    meta: confirmationiqEfo3qVMwMeta || {},
    alias: confirmationiqEfo3qVMwMeta?.alias || [],
    redirect: confirmationiqEfo3qVMwMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/terminal/redeem/confirmation.vue").then(m => m.default || m)
  },
  {
    name: indexmt1Ap7eunWMeta?.name ?? "terminal-redeem",
    path: indexmt1Ap7eunWMeta?.path ?? "/terminal/redeem",
    meta: indexmt1Ap7eunWMeta || {},
    alias: indexmt1Ap7eunWMeta?.alias || [],
    redirect: indexmt1Ap7eunWMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/terminal/redeem/index.vue").then(m => m.default || m)
  },
  {
    name: _91redeemableId_931RYVedKbQ5Meta?.name ?? "terminal-redeem-voucher-reference-redeemableId",
    path: _91redeemableId_931RYVedKbQ5Meta?.path ?? "/terminal/redeem/voucher/:reference()/:redeemableId()",
    meta: _91redeemableId_931RYVedKbQ5Meta || {},
    alias: _91redeemableId_931RYVedKbQ5Meta?.alias || [],
    redirect: _91redeemableId_931RYVedKbQ5Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/terminal/redeem/voucher/[reference]/[redeemableId].vue").then(m => m.default || m)
  },
  {
    name: indexkVc81AzHfGMeta?.name ?? "terminal-redeem-voucher-reference",
    path: indexkVc81AzHfGMeta?.path ?? "/terminal/redeem/voucher/:reference()",
    meta: indexkVc81AzHfGMeta || {},
    alias: indexkVc81AzHfGMeta?.alias || [],
    redirect: indexkVc81AzHfGMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/terminal/redeem/voucher/[reference]/index.vue").then(m => m.default || m)
  }
]